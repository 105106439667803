var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.productList && _vm.productList.length)?_c('div',{staticClass:"awards-list-grid mt-7"},[_c('div',{staticClass:"d-flex justify-center mb-5"},[(_vm.listData && _vm.listData.listId)?_c('v-btn',{attrs:{"color":"primary","depressed":"","large":"","to":{
        name: 'Booking',
        params: { listId: _vm.listData.listId },
        query: {
          storeId:
            _vm.category && _vm.category.store && _vm.category.store.storeId
              ? _vm.category.store.storeId
              : 6
        }
      }}},[_vm._v(" "+_vm._s(_vm.$t("awards.lists.goToCompletePrenotationBtn", { num: _vm.listData && _vm.listData.wishlistItems.length ? _vm.listData.wishlistItems.length : 0 }))+" ")]):_vm._e()],1),_c('span',{staticClass:"d-block product-counter mb-5"},[_vm._v(_vm._s(_vm.$t("awards.products.counter", { count: _vm.productList.length })))]),_c('v-row',_vm._l((_vm.productList),function(product){return _c('v-col',{key:product.productId,staticClass:"product-col",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('AwardsCard',{attrs:{"product":product},on:{"bookProduct":_vm.bookProduct}})],1)}),1),(_vm.$vuetify.breakpoint.xs && _vm.hasMoreItems)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],attrs:{"block":"","loading":_vm.loading},on:{"click":_vm.loadMore}},[(_vm.hasMoreItems)?[_vm._v(_vm._s(_vm.$t("products.showMore")))]:[_vm._v(_vm._s(_vm.$t("products.showAll")))]],2):_vm._e(),(_vm.pager && _vm.pager.totPages > 1 && !_vm.$vuetify.breakpoint.xs)?_c('v-pagination',{staticClass:"py-6",attrs:{"value":_vm.page,"page":_vm.pager.selPage,"length":_vm.pager.totPages ? _vm.pager.totPages : 0,"totalVisible":7},on:{"next":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePageFilter.apply(null, arguments)},"prev":_vm.handlePageFilter,"input":_vm.handlePageFilter}}):_vm._e()],1):(!_vm.loading)?_c('div',[_c('span',{staticClass:"d-block product-counter mb-5"},[_vm._v(_vm._s(_vm.$t("awards.products.noProducts")))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }