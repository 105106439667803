<template>
  <v-card
    outlined
    rounded="md"
    class="product-award-card-container product-card h-100"
    ref="productCard"
    :class="showBtn ? 'get-award-card' : 'history-card'"
  >
    <div class="product">
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center mt-3"
            :width="$vuetify.breakpoint.xs ? 100 : 170"
            :height="$vuetify.breakpoint.xs ? 100 : 170"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div class="description px-2 mt-3">
            <span class="buy-date text-body-2" v-if="buyDate">{{
              buyDate
            }}</span>
            <span class="name">
              {{ product.name }}
            </span>
            <div
              class="awards-promo"
              v-if="
                product.warehousePromo && product.warehousePromo.view.header
              "
              v-html="product.warehousePromo.view.header"
            ></div>
          </div>
        </div>
      </div>
      <div class="actions my-3" v-if="showBtn">
        <!--  -->
        <!-- <ProductPrice v-if="product.priceDisplay" :product="product" /> -->
        <div></div>
        <div class="user-action-preferences">
          <QtyWrap :product="product" />
        </div>
      </div>
    </div>

    <v-card-actions v-if="showBtn">
      <template v-if="product.available > 0 && product.priceDisplay">
        <v-btn
          color="primary"
          depressed
          large
          block
          @click.stop.prevent="selectProduct"
        >
          {{ $t("awards.products.bookBtn") }}
          <v-icon class="ml-2">$editcalendar</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <div class="primary--text text-center w-100 mt-2">
          {{ $t("awards.products.notAvailable") }}
        </div>
      </template>
    </v-card-actions>
    <v-card-text v-if="shippingAddress">
      <div
        class="delivery-container"
        v-if="
          shippingAddress.addressId != 159 && shippingAddress.addressId != 160
        "
      >
        <span><strong>Ritiro presso:</strong></span>
        <span class="location">{{ shippingAddress.addressName }}</span>
        <span
          >{{ shippingAddress.address1 }} {{ shippingAddress.address2 }}</span
        >
        <span
          >{{ shippingAddress.postalCode }} {{ shippingAddress.city }} ({{
            shippingAddress.province
          }})</span
        >
      </div>
      <div
        class="delivery-container"
        v-else-if="shippingAddress.addressId == 159"
      >
        <strong>Consegna con la prossima spesa a Casa</strong>
      </div>
      <div
        class="delivery-container"
        v-else-if="shippingAddress.addressId == 160"
      >
        <strong>Ritiro con la prossima spesa Drive</strong>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.product-award-card-container {
  .product {
    .description {
      height: 88px;
      .awards-promo {
        font-weight: 700;
        text-align: center;
        font-size: 1.25rem;
        background-color: var(--v-green-lighten2);
        padding: 3px 0px;
      }
    }
  }
  .product .actions {
    min-height: 42px;
  }
  &.history-card {
    .description {
      height: 112px;
      margin-bottom: 12px;
    }
  }
}
</style>
<script>
// import ProductPrice from "@/components/product/ProductPrice.vue";
import QtyWrap from "@/components/product/QtyWrap.vue";

import productMixin from "~/mixins/product";

export default {
  name: "AwardsCard",
  props: {
    product: { type: Object, required: true },
    showBtn: { type: Boolean, default: true },
    buyDate: { type: String, required: false },
    shippingAddress: { type: Object, required: false }
  },
  mixins: [productMixin],
  components: {
    // ProductPrice,
    QtyWrap
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    selectProduct() {
      console.log("selectProd");
      this.$emit("bookProduct", {
        product: this.product,
        quantity: this.quantity,
        weight: this.weight
      });
    }
  }
};
</script>
