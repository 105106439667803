<template>
  <div class="awards-list-grid mt-7" v-if="productList && productList.length">
    <div class="d-flex justify-center mb-5">
      <v-btn
        v-if="listData && listData.listId"
        color="primary"
        depressed
        large
        :to="{
          name: 'Booking',
          params: { listId: listData.listId },
          query: {
            storeId:
              category && category.store && category.store.storeId
                ? category.store.storeId
                : 6
          }
        }"
      >
        {{
          $t("awards.lists.goToCompletePrenotationBtn", {
            num:
              listData && listData.wishlistItems.length
                ? listData.wishlistItems.length
                : 0
          })
        }}
      </v-btn>
    </div>

    <span class="d-block product-counter mb-5">{{
      $t("awards.products.counter", { count: productList.length })
    }}</span>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
        v-for="product in productList"
        :key="product.productId"
        class="product-col"
      >
        <AwardsCard v-bind:product="product" @bookProduct="bookProduct" />
      </v-col>
    </v-row>

    <v-btn
      block
      v-intersect="onIntersect"
      :loading="loading"
      @click="loadMore"
      v-if="$vuetify.breakpoint.xs && hasMoreItems"
    >
      <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
      <template v-else>{{ $t("products.showAll") }}</template>
    </v-btn>
    <v-pagination
      v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
      :value="page"
      :page="pager.selPage"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination>
  </div>
  <div v-else-if="!loading">
    <span class="d-block product-counter mb-5">{{
      $t("awards.products.noProducts")
    }}</span>
  </div>
</template>
<script>
import AwardsCard from "@/components/awards/AwardsCard.vue";

import ProductService from "~/service/productService";
import listService from "@/commons/service/listService";

export default {
  name: "AwardstListGrid",
  components: {
    AwardsCard
  },
  props: {
    category: { type: Object, required: true }
  },
  data() {
    return {
      sortFilter: undefined,
      categoryFilter: undefined,
      wordSearched: undefined,
      productList: null,
      pager: {},
      page: 1,
      busy: false,
      key: 0,
      loading: true,
      listData: null
    };
  },
  computed: {
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    }
  },
  methods: {
    async fetchAwardsProductList(isAddMore = false) {
      let _this = this;
      this.loading = true;
      let res = await ProductService.search({
        store_id: this.category?.store.storeId,
        parent_category_id: this.category?.categoryId,
        q: this.wordSearched,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter
      });
      if (res && res.products) {
        if (isAddMore) {
          let prodList = this.productList.concat(res.products);
          _this.productList = prodList;
          console.log("this.productList", this.productList);
        } else {
          this.productList = res.products;
        }
        // this.productList = res.products;
        this.pager = res.page;
      } else {
        this.productList = [];
      }
      this.key++;
      this.loading = false;
    },
    async fetchList() {
      let res = await listService.getList(
        undefined,
        undefined,
        this.category?.store.wishlistTypeId
      );
      this.listData = res ? res : null;
    },
    sortFilterChanged(newSortFilter) {
      this.sortFilter = newSortFilter;
      this.fetchAwardsProductList();
    },
    categoryChanged(newCategoryFilter) {
      this.categoryFilter = newCategoryFilter;
      this.fetchAwardsProductList();
    },
    wordChanged(wordSearched) {
      this.wordSearched = wordSearched;
      this.fetchAwardsProductList();
    },
    handlePageFilter(newPage) {
      var _this = this;
      this.page = newPage;
      console.log("page -> ", this.page);
      this.fetchAwardsProductList();
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.page++;
        this.fetchAwardsProductList(true);
      }
    },
    async bookProduct(productData) {
      let res = await listService.addProductsToList(this.listData.listId, [
        productData
      ]);
      if (res) {
        this.listData = res;
        // go to confirm awards when an award is added
        // this.$router.push({
        //   name: "Booking",
        //   params: { listId: this.listData.listId },
        //   query: { storeId: this.category?.store.storeId }
        // });
      }
    }
  },
  mounted() {
    this.fetchAwardsProductList();
    this.fetchList();
  }
};
</script>
