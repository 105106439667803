<template>
  <div class="booker-awards-container" v-if="category && category.store">
    <CategoryTitle :category="category" />

    <AwardstListGrid :category="category" />
  </div>
</template>
<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import AwardstListGrid from "@/components/awards/AwardstListGrid.vue";

import categoryMixins from "~/mixins/category";

import { mapGetters } from "vuex";

export default {
  name: "Awards",
  components: {
    CategoryTitle,
    AwardstListGrid
  },
  mixins: [categoryMixins],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {},
  mounted() {}
};
</script>
